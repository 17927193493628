export default [
  {
    header: 'صفحه ها',
    action: 'read',
  },
  {
    title: 'پروفایل کاربری',
    route: 'pages-profile',
    icon: 'MailIcon',
    resource: 'صفحه ها',
    action: 'read',
  },
  {
    title: 'قرارداد ها',
    route: 'contracts',
    icon: 'GridIcon',
    resource: 'صفحه ها',
    action: 'read',
  },
   {
    title: 'عقد قرارداد',
    route: 'makeContract',
    icon: 'PlusIcon',
    resource: 'صفحه ها',
    action: 'read',
  },
]
